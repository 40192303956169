import React from 'react';
import {SelectionsPage} from "benlux-ecommerce-ui";
import {CategoryQuery} from "../../../queries/CategoryQuery";
import Helmet from 'react-helmet';

export const Selections = () => {

    return (
      <CategoryQuery variables={{
        categoryId: '18',
      }}>
        {({category, selectionsContents}) => {

          const {name, image, seo, urlPath} = category;
          const title = seo.title || name;
          const description = seo.description || category.description || '';

          return (
            <>
              <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={'https://www.benlux.fr' + urlPath}/>
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title + ' ‣ BENLUX'} />
                {image && (<meta property="og:image" content={image} />)}
                <meta property="og:description" content={description} />
                <meta property="og:url" content={'https://www.benlux.fr' + urlPath} />
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="twitter:domain" content="benlux.fr"/>
                <meta property="twitter:url" content={'https://www.benlux.fr' + urlPath}/>
                <meta name="twitter:title" content={title + ' ‣ BENLUX'}/>
                <meta name="twitter:description" content={description}/>
                {image && (<meta name="twitter:image" content={image} />)}
                <meta name="page-loaded" content="true" />
                <meta name="page-loaded" content="true" />
              </Helmet>
              <SelectionsPage
                contents={{selectionsContents}}
                category={category}
               />
             </>
          )
        }}
      </CategoryQuery>

    );
}

export default Selections;
