import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_INSTAGRAM_POSTS = gql`
  query InstagramPosts {
    instagramPosts {
        posts {
          thumbnailUrl
          url
        }
    }
  }
`;

export class InstagramPostsQuery extends Query {
  static defaultProps = {
    query: GET_INSTAGRAM_POSTS,
  };
}
