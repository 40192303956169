import React from 'react';
import Helmet from 'react-helmet';

import {InspirationsPage} from 'benlux-ecommerce-ui';

import {CategoryQuery} from '../../../queries/CategoryQuery';
import {InstagramPostsQuery} from "../../../queries/InstagramPostsQuery";
import {Loader} from "@deity/falcon-ecommerce-uikit";

const Inspirations = () => {

  return (
    <CategoryQuery variables={{
      categoryId: '20',
    }}>
      {({category, selectionsContents, inspirationsContents}) => {
        return (
          <>
            <Helmet>
              <title>{category.name}</title>
              <meta property="og:type" content="website"/>
              <meta property="og:title" content={category.name + " ‣ BENLUX"}/>
              <meta property="og:image" content={category.image}/>
              <meta property="og:description" content={category.description}/>
              <meta property="og:url" content={'https://www.benlux.fr' + category.urlPath}/>
              <meta name="twitter:card" content="summary_large_image"/>
              <meta property="twitter:domain" content="benlux.fr"/>
              <meta property="twitter:url" content={'https://www.benlux.fr' + category.urlPath}/>
              <meta name="twitter:title" content={category.name + " ‣ BENLUX"}/>
              <meta name="twitter:description" content={category.description}/>
              <meta name="twitter:image" content={category.image}/>
              <meta name="page-loaded" content="true" />
            </Helmet>
            <InstagramPostsQuery>
              {({instagramPosts}) => (
                <InspirationsPage
                  contents={{selectionsContents, inspirationsContents}}
                  category={category}
                  instagramPosts={instagramPosts.posts}
                />
              )}
            </InstagramPostsQuery>
          </>
        );
      }}
    </CategoryQuery>
  )
}


export default Inspirations;
