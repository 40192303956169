import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_CATEGORY = gql`
  query HomepageProducts($categoryId: String!, $amount: Int) {
    selectionsContents: cmsBlock(identifier: "header-selections-contents") {
        content
    }
    inspirationsContents: cmsBlock(identifier: "header-inspirations-contents") {
      content
    }
    category(id: $categoryId) {
      name
      description
      longDescription
      image
      urlPath
      children {
        name
        description
        urlPath
        products(pagination: { perPage: $amount, page: 1 }) {
          items {
            brandName
            id
            name
            shortDescription
            promotionalBadge
            price {
              regular
              special
            }
            thumbnail
            urlPath
            attributes {
              attributeCode
              value
            }
            configurableOptions {
              values {
                label
                price {
                  regular
                  special
                  minTier
                  suggestedRetail
                }
              }
            }
          }
        }
      }
    }
  }
`;

export class CategoryQuery extends Query {
  static defaultProps = {
    query: GET_CATEGORY,
  };
}
